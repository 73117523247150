import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
export default function ReferenzCapGemini() {
    return (
        <div className="bg-white pt-16 lg:py-24">
            <div className="pb-16 bg-diit lg:pb-0 lg:z-10 lg:relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
                    <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                            <blockquote>
                                <div>
                                    <svg
                                        className="h-12 w-12 text-white opacity-25"
                                        fill="currentColor"
                                        viewBox="0 0 32 32"
                                        aria-hidden="true"
                                    >
                                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                    </svg>
                                    <p className="mt-6 text-2xl font-medium text-white">
                                        Herr Illenberger erfüllte unsere Anforderungen stets zu unserer vollsten Zufriedenheit. Sein Auftreten und die Kommunikation mit unserem Kunden
                                        war tadellos.</p><p className="mt-6 text-2xl font-medium text-white">Seine umfangreiche Entwicklungserfahrung trug viel dazu bei, die hohen Kundenanforderungen
                                    an Codequalität zu erfüllen. </p>
                                </div>
                                <footer className="mt-6">
                                    <p className="text-base font-medium text-yellow-500">Daniel Weinberger</p>
                                    <p className="text-base font-medium text-gray-200">Senior Project Manager, Capgemini</p>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    )
}