import * as React from "react"
import Navigationsleiste from "../components/Navigationsleiste";
import WelcomeSection from "../components/WelcomeSection";
import DreiSaeulen from "../components/DreiSaeulen";
import ReferenzCapGemini from "../components/ReferenzCapGemini";
import Footer from "../components/Footer";
import Seo from "../components/seo";

export default function Startseite() {
        return (
            <>
            <Seo title="Software-Entwicklung in .NET und Delphi" />
            <div className="relative bg-gray-50 font-sans">
                    <Navigationsleiste />

                    <main className="lg:relative">
                        <WelcomeSection />
                    </main>
                <DreiSaeulen />
                <ReferenzCapGemini />
                <Footer />
            </div>
            </>
        )
}
