import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";


export default function WelcomeSection() {
    return (
        <div>
            <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left font-sans">


                <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                    <div>

                                                  <span className="rounded bg-blue-100 px-2.5 py-1 text-xs font-semibold text-blue-700 tracking-wide">
                                                    C#/.NET
                                                  </span>

                        <span className="rounded ml-3 bg-blue-100 px-2.5 py-1 text-xs font-semibold text-blue-700 tracking-wide">
                                                            Delphi
                                                          </span>

                        <span className="rounded ml-3 bg-blue-100 px-2.5 py-1 text-xs font-semibold text-blue-700 tracking-wide">
                                                            High Speed Websites
                                                          </span>
                    </div>


                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-5xl">
                        <span className="block xl:inline font-sans">Software Entwicklung</span>{' '}
                        <span className="block text-diit xl:inline font-sans">für Ihr Business</span>
                    </h1>
                    <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                        Sie suchen einen erfahrenen .NET- oder Delphi-Entwickler oder möchten eine High-Speed-Webseite
                        bauen? Ihnen ist Qualität wichtig? Dann sollten wir uns kennenlernen.
                    </p>


                    <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                        <div className="rounded-md shadow">
                            <Link
                                to="/Kontakt"
                                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-diit hover:bg-blue-800 md:py-4 md:text-lg md:px-10"
                            >
                                Kontakt
                            </Link>
                        </div>
                        <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 ">
                            <Link
                                to="/UeberMich/Steckbrief"
                                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-diit bg-white hover:bg-blue-100 md:py-4 md:text-lg md:px-10"
                            >
                                Steckbrief
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
                <StaticImage
                    src="../images/sourcecode2.jpg"
                    formats={["auto", "webp", "avif"]}
                    alt="Begrüßungsbild"
                    className="absolute inset-0 w-full h-full object-cover"
                    loading="lazy"
                />

            </div>
        </div>
    )
}
